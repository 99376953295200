import React from "react";
import BaseLayout from "../components/BaseLayout";
import { Widget } from "@typeform/embed-react";

import { StyledPre } from "../styles/StyledPre";

function Pre({ pageContext: { fechedOfferList } }) {
  const queryString =
    typeof window !== "undefined" ? window.location.search : "";

  const urlParams = new URLSearchParams(queryString);
  const idc = urlParams.get("idc");
  const numeroid = urlParams.get("numeroid");
  return (
    <BaseLayout destailPage={true} footerMap={false} footerAdress={false}>
      <StyledPre>
        <Widget
          id={`ny3WeZSM#idc=${idc}&numeroid=${numeroid}`}
          style={{ width: "100%" }}
          className="stasForm"
        />
      </StyledPre>
    </BaseLayout>
  );
}

export default Pre;
